import React from 'react';

interface Props {
  color?: string;
}

function IconInfo(props: Props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25762 14V6.832H9.74162V14H8.25762ZM7.90762 4.83C7.90762 4.102 8.34162 3.92 9.01362 3.92C9.65762 3.92 10.0776 4.102 10.0776 4.83C10.0776 5.544 9.65762 5.726 9.01362 5.726C8.34162 5.726 7.90762 5.544 7.90762 4.83Z"
        fill={props.color ?? 'currentColor'}
      />
      <rect
        x="1"
        y="1"
        width="16"
        height="16"
        rx="4"
        stroke={props.color ?? 'currentColor'}
      />
    </svg>
  );
}

export const SvgIconInfo = React.memo(IconInfo);
