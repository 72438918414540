import {
  differenceInMonths,
  differenceInYears,
  format,
  formatISO,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { DateString } from '@/graphql/generated';

export const relativeTime = (
  date: Date,
  targetDate: Date
): {
  time: number;
  suffix:
    | 'years'
    | 'months'
    | 'weeks'
    | 'days'
    | 'hours'
    | 'minutes'
    | 'seconds';
} => {
  const now = date.getTime();
  const diff = now - targetDate.getTime();
  const time = Math.floor(diff / 1000);
  if (time < 60) {
    return { time: Math.max(time, 0), suffix: 'seconds' };
  }
  const minute = Math.floor(time / 60);
  if (minute < 60) {
    return { time: minute, suffix: 'minutes' };
  }
  const hour = Math.floor(minute / 60);
  if (hour < 24) {
    return { time: hour, suffix: 'hours' };
  }
  const day = Math.floor(hour / 24);
  if (day < 7) {
    return { time: day, suffix: 'days' };
  }
  const month = differenceInMonths(now, targetDate);
  const year = differenceInYears(now, targetDate);
  if (month < 1) {
    const week = Math.floor(day / 7);
    return { time: week, suffix: 'weeks' };
  }
  if (year < 1) {
    return { time: month, suffix: 'months' };
  }
  return { time: year, suffix: 'years' };
};

/**
 * dateStringToString
 * DateString を JST の日付文字列に変換する
 *
 * @param {DateString} date
 * @param {string} [dateFormat='yyyy/MM/dd HH:mm:ss']
 * @return {*}  {string}
 */
export const dateStringToString = (
  date: DateString,
  dateFormat = 'yyyy/MM/dd HH:mm:ss'
): string => {
  const utcDate = new Date(date);
  const jstDate = utcToZonedTime(utcDate, 'Asia/Tokyo');
  const jstString = format(jstDate, dateFormat);
  return jstString;
};

/**
 * dateToDateString
 * Date オブジェクトを DateString に変換する
 *
 * @param {Date} date
 * @param {(Extract<
 *     NonNullable<Parameters<typeof formatISO>['1']>['representation'],
 *     'complete' | 'date'
 *   >)} [representation]
 * @return {*}  {DateString}
 */
export const dateToDateString = (
  date: Date,
  representation?: Extract<
    NonNullable<Parameters<typeof formatISO>['1']>['representation'],
    'complete' | 'date' | 'time'
  >
): DateString => {
  return formatISO(date, { representation }) as DateString;
};

/**
 * isInvalidDate
 * Date オブジェクトが不正な日付かどうかを判定する
 * @param {Date} date
 * @return {*}  {boolean}
 *
 * @example
 * isInvalidDate(new Date('2021-01-01')) // false
 * isInvalidDate(new Date('2021-01-32')) // true
 */
export const isInvalidDate = (date: Date): boolean =>
  Number.isNaN(date.getTime());
