import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { useTranslation } from '@/app/i18n/client';
import { SvgIconCell2 } from '@/components/assets/IconCell2';
import { SvgIconHeart } from '@/components/assets/IconHeart';
import { SvgIconNft } from '@/components/assets/IconNft';
import { SvgIconNoStampLogo } from '@/components/assets/IconNoStampLogo';
import { Card } from '@/components/common/Card/Card';
import { InformationButton } from '@/components/common/InformationButton';
import { SquareImageCard } from '@/components/common/SquareImageCard';
import { PointIcon } from '@/components/pages/V2/Home/PointIcon';
import { MAX_CELL_COUNT } from '@/constants';

type Props = {
  facilities?: number;
  nfts?: number;
  yellCount?: number;
  myRevealedCellsCount?: number;
  homeMyStampImgUrl?: string;
};
export const Summary: FC<Props> = ({
  facilities,
  nfts,
  yellCount,
  myRevealedCellsCount,
  homeMyStampImgUrl,
}) => {
  const router = useRouter();
  const { lng } = router.query;
  const { t } = useTranslation(['common', 'dictionary', 'stamp']);

  return (
    <VStack gap="10" w="full">
      <VStack gap="4" w="full">
        {homeMyStampImgUrl ? (
          <SquareImageCard alt="stamp_image" src={homeMyStampImgUrl} />
        ) : (
          <Card bg="rgba(235, 235, 237, 0.40)" noPadding>
            <VStack gap="0">
              <Flex w="full" justifyContent="flex-end" p="5px">
                <SvgIconNoStampLogo />
              </Flex>
              <Text
                fontSize="sm"
                color="text.ultraDarkGray"
                fontWeight="medium"
                textAlign="center"
                wordBreak="break-all"
                whiteSpace="pre-wrap"
              >
                {t(
                  'stamp:no_stamps_message',
                  'HOSHIをグリーンにして\nAIアートをゲットしよう！'
                )}
              </Text>
              <Image
                alt="stamp_image"
                src="/no_stamps.png"
                width={160}
                height={160}
              />
            </VStack>
          </Card>
        )}
        <Card flex={1} minW="fit-content">
          <HStack align="center" whiteSpace="nowrap" w="full">
            <PointIcon color="#FF7881">
              <SvgIconHeart size={24} color="#FF7881" />
            </PointIcon>
            <VStack gap={0.5} align="flex-start" w="full">
              <HStack justify="space-between" w="full">
                <Text fontSize="md" fontWeight="medium">
                  {t('dictionary:checked', 'エール')}
                  {lng === 'ja' && '数'}
                </Text>
                <InformationButton
                  aria-label={t('common:about', {
                    word: t('dictionary:checked', 'エール数'),
                  })}
                  title={t('dictionary:checked', 'エール数')}
                  subTitle={'[checked]'}
                  description={t(
                    'dictionary:checked_gloss',
                    'キミが設備ページの「チェック」ボタンを押してCO2削減量を見る回数を数えているよ。\n「チェック」することで、企業はキミに毎日の努力を伝えることができるし、キミは企業の活動を知って、環境問題についてもっと意識するようになるんだ。'
                  )}
                  key="checked-description-dialog"
                />
              </HStack>
              <Text
                fontSize="xl"
                fontWeight="bold"
                lineHeight={1}
                color="text.pink"
              >
                {yellCount?.toLocaleString()}
              </Text>
            </VStack>
          </HStack>
        </Card>
        <Card flex={1} minW="fit-content">
          <HStack align="center" whiteSpace="nowrap" w="full">
            <PointIcon color="#8F80FF">
              <SvgIconNft size={24} color="#8F80FF" />
            </PointIcon>
            <VStack gap={0.5} align="flex-start" w="full">
              <HStack justify="space-between" w="full">
                <Text fontSize="md" fontWeight="medium">
                  {t('dictionary:facility', '環境貢献カード')}
                </Text>
                <InformationButton
                  aria-label={t('common:about', {
                    word: t('dictionary:facility', '環境貢献カード'),
                  })}
                  title={t('dictionary:facility', '環境貢献カード')}
                  subTitle={'[facility]'}
                  description={t(
                    'dictionary:facility_gloss',
                    'キミがcapture.xで持っている設備の種類の数。\nカッコ内の数字は、そのNFTの枚数だよ。\nたとえば、「bajjiソーラー北海道1」を3枚持っていたら、「1（3）」って表示されるんだ。'
                  )}
                  key="facility-description-dialog"
                />
              </HStack>
              <Text
                fontSize="xl"
                fontWeight="bold"
                lineHeight={1}
                color="text.purple"
              >
                {nfts?.toLocaleString()}
              </Text>
            </VStack>
          </HStack>
        </Card>
        <Card flex={1} minW="fit-content">
          <HStack align="center" whiteSpace="nowrap" w="full">
            <PointIcon color="#3AD57F">
              <SvgIconCell2 size={24} color="#3AD57F" />
            </PointIcon>
            <VStack gap={0.5} align="flex-start" w="full">
              <HStack justify="space-between" w="full">
                <Text fontSize="md" fontWeight="medium">
                  {t('dictionary:revealed_cells', 'グリーンにしたマス')}
                </Text>
                <InformationButton
                  aria-label={t('common:about', {
                    word: t('dictionary:revealed_cells', 'グリーンにしたマス'),
                  })}
                  title={t('dictionary:revealed_cells', 'グリーンにしたマス')}
                  subTitle={'[revealed cells]'}
                  description={t(
                    'dictionary:revealed_cells_gloss',
                    '2つめのミッション「HOSHIをグリーンに」で、キミがグリーンに変えたマスの数をカウントしている。'
                  )}
                  key="revealed-cell-description-dialog"
                />
              </HStack>
              <HStack justify="space-between" align="flex-end" w="full">
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  lineHeight={1}
                  color="text.green"
                >
                  {myRevealedCellsCount?.toLocaleString()}
                </Text>
                <Text
                  as="span"
                  fontSize="md"
                  fontWeight="medium"
                  color="text.gray"
                >
                  /{MAX_CELL_COUNT.toLocaleString()}
                </Text>
              </HStack>
            </VStack>
          </HStack>
        </Card>
      </VStack>
    </VStack>
  );
};
