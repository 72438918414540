import { Card as ChakraCard, CardProps, forwardRef } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props extends Omit<CardProps, 'boxShadow' | 'p' | 'borderRadius'> {
  noPadding?: boolean;
  children: ReactNode;
  shadow?: 'none' | 'medium' | 'heavy';
}

const ShadowMap = {
  none: 'none',
  medium: '0px 4px 16px 0px #00000014',
  heavy: '0px 8px 24px 0px #00000029',
} as const;

export const Card = forwardRef<Props, 'div'>(
  (
    {
      bg = 'white',
      w = 'full',
      noPadding = false,
      shadow = 'medium',
      children,
      ...props
    },
    ref
  ) => {
    return (
      <ChakraCard
        {...props}
        ref={ref}
        bg={bg}
        boxShadow={ShadowMap[shadow]}
        p={noPadding ? '0' : '4'}
        borderRadius="16px"
        w={w}
      >
        {children}
      </ChakraCard>
    );
  }
);
