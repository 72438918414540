import {
  Box,
  Flex,
  HStack,
  SkeletonText,
  Text,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import router from 'next/router';
import { FC } from 'react';

import { useTranslation } from '@/app/i18n/client';
import { SvgIconChevronCircle } from '@/components/assets/IconChevronCircle';
import { SvgRakutenPointLogo } from '@/components/assets/RakutenPointLogo';
import { ListTable } from '@/components/common/ListTable/ListTable';
import { PointHistories } from '@/components/pages/V2/Home/PointHistories';
import usePointDetail, {
  PAGE_SIZE,
} from '@/components/pages/V2/Home/usePointDetail';
import { Paths } from '@/constants/path';
import { useIsNativeApp } from '@/hooks/useIsNativeApp';
import { createPath } from '@/utils/path';

interface Props {
  type: 'co2' | 'check';
}

interface PointDetailItem {
  type: 'co2' | 'check';
  balancePoint: number | undefined;
  lifetimePoint: number | undefined;
  annualPoint: number | undefined;
  onToday: () => void;
  onWeek: () => void;
  onMonth: () => void;
  onYear: () => void;
  onAll: () => void;
}

export const PointDetail: FC<Props> = ({ type }) => {
  const { t } = useTranslation(['common', 'home']);
  const isNativeApp = useIsNativeApp();
  const {
    co2PointAllAmount,
    co2PointTotalBalance,
    onCo2PointAll,
    onCo2PointYear,
    onCo2PointMonth,
    onCo2PointWeek,
    onCo2PointToday,
    yellPointAllAmount,
    yellPointTotalBalance,
    onYellPointAll,
    onYellPointYear,
    onYellPointMonth,
    onYellPointWeek,
    onYellPointToday,
    currentDate,
    yellPointAmountOfThisYear,
    co2PointAmountOfThisYear,
    pageVariables,
    setPageVariables,
  } = usePointDetail();

  const items: { [_key in Props['type']]: PointDetailItem } = {
    co2: {
      type: 'co2',
      balancePoint: co2PointTotalBalance,
      lifetimePoint: co2PointAllAmount,
      annualPoint: co2PointAmountOfThisYear,
      onToday: onCo2PointToday,
      onWeek: onCo2PointWeek,
      onMonth: onCo2PointMonth,
      onYear: onCo2PointYear,
      onAll: onCo2PointAll,
    },
    check: {
      type: 'check',
      balancePoint: yellPointTotalBalance,
      lifetimePoint: yellPointAllAmount,
      annualPoint: yellPointAmountOfThisYear,
      onToday: onYellPointToday,
      onWeek: onYellPointWeek,
      onMonth: onYellPointMonth,
      onYear: onYellPointYear,
      onAll: onYellPointAll,
    },
  };
  const date = format(currentDate, 'yyyy.MM.dd');

  const { balancePoint, lifetimePoint, annualPoint } = items[type];

  return (
    <VStack gap="6" w="full" align="flex-start">
      {type === 'check' && !isNativeApp && (
        <Flex
          p="8px 16px"
          w="full"
          boxShadow="0 4px 16px rgba(0, 0, 0, 0.08)"
          borderRadius="16px"
          my="24px"
          cursor="pointer"
          justifyContent="center"
          alignItems="center"
          onClick={() => {
            router.push(createPath({ path: Paths.yellPointExchange }));
          }}
        >
          <SvgRakutenPointLogo size={32} />
          <Text
            ml="8px"
            fontSize="sm"
            fontWeight="regular"
            letterSpacing="0.05em"
          >
            {t('home:redeem_yell_points')}
          </Text>
          <Box ml="auto">
            <SvgIconChevronCircle rotate="rotate(180deg)" size="24px" />
          </Box>
        </Flex>
      )}
      <VStack gap="2" align="flex-start" w="full">
        <Text color="text.gray" fontSize="xs" fontWeight="medium">
          {date}
        </Text>
        <HStack gap="1" align="center">
          {balancePoint ? (
            <Text fontSize="2xl" fontWeight="bold">
              {Number(balancePoint).toLocaleString()}
            </Text>
          ) : (
            <SkeletonText w="120px" noOfLines={1} skeletonHeight="8" />
          )}
        </HStack>
        {/* グラフいったんなくす */}
        {/* <Box w="calc(100% + 48px)" mx="-6">
          <CustomAreaChart lineColor="#3AD57F" data={chartMemo} />
        </Box>
        <Flex justify="center" align="center" w="full">
          <HStack justify="center" gap="2">
            <Button
              id="one-day"
              size="md"
              variant="ghost"
              borderRadius="20px"
              p={0}
              onClick={onToday}
              isActive={activeTerm === 'day'}
            >
              1D
            </Button>
            <Button
              id="one-week"
              size="md"
              variant="ghost"
              borderRadius="20px"
              p={0}
              onClick={onWeek}
              isActive={activeTerm === 'week'}
            >
              1W
            </Button>
            <Button
              id="one-month"
              size="md"
              variant="ghost"
              borderRadius="20px"
              p={0}
              onClick={onMonth}
              isActive={activeTerm === 'month'}
            >
              1M
            </Button>
            <Button
              id="one-year"
              size="md"
              variant="ghost"
              borderRadius="20px"
              p={0}
              onClick={onYear}
              isActive={activeTerm === 'year'}
            >
              1Y
            </Button>
            <Button
              id="all"
              size="md"
              variant="ghost"
              borderRadius="20px"
              p={0}
              onClick={onAll}
              isActive={activeTerm === 'all'}
            >
              All
            </Button>
          </HStack>
        </Flex> */}
      </VStack>
      <VStack gap="8" py="6" w="full">
        <ListTable
          icon={
            <Box
              display="inline-block"
              width="16px"
              height="16px"
              backgroundColor="#D9D9D9"
            />
          }
          title={t('home:check_details', '詳細')}
          items={[
            {
              label: t('home:lifetime', '生涯'),
              value: Number(lifetimePoint).toLocaleString(),
            },
            {
              label: t('annual', '年間'),
              value: Number(annualPoint).toLocaleString(),
            },
          ]}
        />
      </VStack>
      {type === 'co2' && (
        <VStack gap="4" w="full" align="flex-start">
          <Flex gap="1" justify="flex-start" w="full" align="center">
            <Box
              display="inline-block"
              width="16px"
              height="16px"
              backgroundColor="#D9D9D9"
            />
            <Text color="text.black" fontSize="md" fontWeight="medium">
              {t('home:co2_point_logs', '履歴')}
            </Text>
          </Flex>
          <VStack w="full" gap="4">
            {pageVariables.map((variables, i) => (
              <PointHistories
                key={variables.offset}
                currentDate={currentDate}
                variables={variables}
                isLastPage={i === pageVariables.length - 1}
                onLoadMore={(offset) => {
                  setPageVariables([
                    ...pageVariables,
                    { offset, limit: PAGE_SIZE },
                  ]);
                }}
              />
            ))}
          </VStack>
        </VStack>
      )}
    </VStack>
  );
};
