import { Icon } from '@chakra-ui/react';
import React from 'react';

type Props = {
  rotate?: string;
  size?: string;
  iconColor?: string;
  iconFill?: string;
};
function IconChevronCircle(props: Props) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '32px'}
      height={props.size ?? '32px'}
      fill="none"
      viewBox="0 0 32 32"
      transform={props.rotate || 'rotate(0deg)'}
    >
      <rect
        width="32"
        height="32"
        fill={props.iconFill || '#F8F9FD'}
        rx="16"
      ></rect>
      <path
        fill={props.iconColor || '#020202'}
        d="M10.67 16.003a1.328 1.328 0 00.392.943l8 8a1.333 1.333 0 001.885-1.885l-7.057-7.058 7.057-7.057a1.333 1.333 0 00-1.885-1.885l-8 8a1.33 1.33 0 00-.391.942z"
      ></path>
    </Icon>
  );
}

export const SvgIconChevronCircle = React.memo(IconChevronCircle);
