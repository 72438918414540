import React from 'react';

interface Props
  extends Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'color'> {}

function IconCloseBottom(props: Props) {
  return (
    <svg
      width={props.width ?? '12'}
      height={props.height ?? '12'}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99913 9.42665C5.77161 9.42692 5.55334 9.33644 5.39262 9.17534L0.245734 4.02845C0.0323435 3.81112 -0.0498181 3.49695 0.0299416 3.20294C0.109624 2.909 0.339287 2.67933 0.633226 2.59966C0.927233 2.51991 1.2414 2.60207 1.45874 2.81545L5.99915 7.35587L10.5396 2.81545C10.7567 2.601 11.0714 2.5181 11.366 2.59772C11.6606 2.67734 11.8907 2.90748 11.9703 3.20202C12.0499 3.49663 11.967 3.81134 11.7526 4.02847L6.60569 9.17536C6.44499 9.33647 6.22671 9.42694 5.99918 9.42666L5.99913 9.42665Z"
        fill={props.color ?? 'currentColor'}
      />
    </svg>
  );
}

export const SvgIconCloseBottom = React.memo(IconCloseBottom);
