import { HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { ComponentProps, FC, MouseEventHandler } from 'react';

import { useTranslation } from '@/app/i18n/client';
import { SvgIconCloseBottom } from '@/components/assets/IconCloseBottom';
import { SvgIconInfo } from '@/components/assets/IconInfo';
import { IconButton } from '@/components/common/IconButton/IconButton';
import { HalfScreenModal } from '@/components/common/Modal/HalfScreenModal';

interface Props
  extends Pick<
    ComponentProps<typeof IconButton>,
    'aria-label' | 'size' | 'variant' | 'colorScheme'
  > {
  title: string;
  subTitle: string;
  description: string;
  key: string;
}

export const InformationButton: FC<Props> = ({
  'aria-label': label,
  size = 'xs',
  title,
  subTitle,
  description,
  key,
  variant,
  colorScheme,
}) => {
  // animation のために useDisclosure を使う
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation(['common']);

  const handleOpen: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <IconButton
        aria-label={label}
        icon={<SvgIconInfo />}
        size={size}
        variant={variant}
        colorScheme={colorScheme}
        onClick={handleOpen}
      />
      <HalfScreenModal isOpen={isOpen} handleClose={handleClose} key={key}>
        <HStack
          justifyContent="flex-start"
          position="sticky"
          top={0}
          w="full"
          bg="white"
          py="4"
          px="6"
        >
          <IconButton
            aria-label={t('common:action.close')}
            icon={<SvgIconCloseBottom />}
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
          />
        </HStack>
        <VStack
          w={{ base: 'full', sm: 'full', md: '400px' }}
          mx="auto"
          overflow="scroll"
          h="calc(50vh - 24px - 68px)" // スクロール範囲
          pb="40px"
        >
          <VStack gap="6" p="6" w="full" align="flex-start">
            <VStack
              gap="3"
              pl="4"
              w="full"
              align="flex-start"
              position="relative"
              _before={{
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '8px',
                height: '100%',
                background: 'bg.green',
              }}
            >
              <Text fontSize="ml" fontWeight="bold" lineHeight="1.2">
                {title}
              </Text>
              <Text
                fontSize="sm"
                fontWeight="medium"
                color="text.gray"
                lineHeight="1.2"
              >
                {subTitle}
              </Text>
            </VStack>
            <Text fontSize="md" color="text.semiDarkGray" whiteSpace="pre-wrap">
              {description}
            </Text>
          </VStack>
        </VStack>
      </HalfScreenModal>
    </>
  );
};
