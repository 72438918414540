
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[lng]/v2",
      function () {
        return require("private-next-pages/[lng]/v2/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[lng]/v2"])
      });
    }
  