import React from 'react';

type Props = {
  fill?: string;
  size?: string;
};

function IconCo2Point(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '18'}
      height={props.size ?? '18'}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill={props.fill ?? 'currentColor'}
        d="M6.62 13.458c0 1.506-.944 1.827-2.024 1.827s-2.025-.34-2.025-1.827c0-1.308.722-1.79 1.537-1.79v.896c-.481.055-.808.271-.808.894 0 .803.574.945 1.296.945.728 0 1.296-.142 1.296-.945 0-.63-.321-.833-.815-.894v-.902c.864 0 1.543.47 1.543 1.796zM6.62 8.805c0 1.549-.944 1.888-2.024 1.888S2.57 10.348 2.57 8.805c0-1.555.945-1.895 2.03-1.895 1.075 0 2.019.34 2.019 1.895zm-2.024.993c.728 0 1.296-.142 1.296-.993 0-.864-.568-1-1.29-1-.728 0-1.302.136-1.302 1 0 .851.574.993 1.296.993zM5.855 5.855c-1.562-.012-1.229-2.098-1.981-2.172-.365-.037-.562.173-.562.61 0 .482.228.73.654.73v.82c-.796 0-1.395-.42-1.395-1.524 0-1.13.58-1.55 1.346-1.5 1.333.092 1.043 2.203 1.9 2.203h.05V2.82h.704c-.007 1.864 0 1.247 0 3.042l-.716-.006z"
      ></path>
      <path
        fill={props.fill ?? 'currentColor'}
        d="M7.714 10.8H9V15.429H7.714z"
      ></path>
      <path
        fill={props.fill ?? 'currentColor'}
        d="M11.314 3.857h-3.6V2.571h3.6a4.114 4.114 0 110 8.229h-3.6V9.514h3.6a2.829 2.829 0 100-5.657z"
      ></path>
      <circle
        cx="14.528"
        cy="14.528"
        r="0.9"
        fill={props.fill ?? 'currentColor'}
      ></circle>
    </svg>
  );
}

export const SvgIconCo2Point = React.memo(IconCo2Point);
