import { NextPage } from 'next';
import Head from 'next/head';

import { useTranslation } from '@/app/i18n/client';
import { V2BGLayout } from '@/components/common/Layout/V2BGLayout/PageRouter';
import { LoadingScreen } from '@/components/common/LoadingScreen';
import { HomePage } from '@/components/pages/V2/Home';

const Home: NextPage = () => {
  const { ready } = useTranslation(['common', 'auth', 'home']);
  if (!ready) return <LoadingScreen />;

  return (
    <>
      <Head>
        <title>Home | capture.x キャプチャーエックス</title>
      </Head>
      <V2BGLayout bottomNav>
        <HomePage />
      </V2BGLayout>
    </>
  );
};

export default Home;
