import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql } from 'urql';

import { Loading } from '@/components/common/Loading';
import { usePointHistories } from '@/components/pages/V2/Home/usePointHistories';
import { relativeTime } from '@/utils/date';

gql`
  fragment MyCo2PointHistoriesPagination on MyCo2PointHistoriesPaginationOutput {
    data {
      id
      amount
      type
      createdAt
    }
    total
    hasNextPage
  }
`;

gql`
  query myCo2PointHistories($input: MyCo2PointHistoriesPaginationInput!) {
    webMyCo2PointHistoriesPagination(input: $input) {
      ...MyCo2PointHistoriesPagination
    }
  }
`;

interface Props {
  currentDate: Date;
  isLastPage: boolean;
  variables: { limit: number; offset: number };
  onLoadMore: (offset: number) => void;
}

export const PointHistories = ({
  currentDate,
  variables,
  isLastPage,
  onLoadMore,
}: Props) => {
  const { t } = useTranslation(['common', 'home']);
  const { fetching, hasNextPage, loadingRef, histories } = usePointHistories({
    isLastPage,
    variables,
    onLoadMore,
  });

  return (
    <>
      {fetching && (
        <Flex w="full" justify="center" align="center" py="48px">
          <Loading />
        </Flex>
      )}
      {histories?.map((history) => (
        <HStack key={history.id} gap="4" flexWrap="nowrap" w="full" h="50px">
          <VStack align="flex-start" flex="1" gap="1">
            <Text
              fontWeight="medium"
              fontSize="sm"
              lineHeight="1.2"
              overflow="hidden"
              display="-webkit-box"
              textOverflow="ellipsis"
              sx={{
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {history.type === 'ADD'
                ? t('home:add', '追加')
                : t('home:reduce', '消費')}
            </Text>
            <Text fontSize="xs" color="text.gray" lineHeight="1">
              {t(
                `common:relative_time.${
                  relativeTime(currentDate, history.createdAt).suffix
                }`,
                {
                  time: relativeTime(currentDate, history.createdAt).time,
                }
              )}
            </Text>
          </VStack>
          <HStack gap="0.5" color="text.gray">
            <Text fontWeight="medium" fontSize="xs">
              {history.type === 'ADD' ? '+' : '-'}
            </Text>
            <Text fontWeight="medium" fontSize="xs">
              {Number(history.amount).toFixed(2)}
            </Text>
          </HStack>
        </HStack>
      ))}
      {isLastPage && hasNextPage && (
        <Flex
          ref={loadingRef}
          w="full"
          justify="center"
          align="center"
          py="48px"
        >
          <Loading />
        </Flex>
      )}
    </>
  );
};
