import { Flex, HStack, SkeletonText, Text, VStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from 'urql';

import { SvgBtnCard } from '@/components/assets/BtnCard';
import { SvgBtnSetting } from '@/components/assets/BtnSetting';
import { BaseBG } from '@/components/common/BaseBG/BaseBG';
import { IconButton } from '@/components/common/IconButton/IconButton';
import { CertificateSummary } from '@/components/pages/V2/Home/CertificateSummary';
import { InformationDetail } from '@/components/pages/V2/Home/InformationDetail';
import { PointCards } from '@/components/pages/V2/Home/PointCards';
import { Summary } from '@/components/pages/V2/Home/Summary';
import useHome from '@/components/pages/V2/Home/useHome';
import { Paths } from '@/constants/path';
import { useIsNativeApp } from '@/hooks/useIsNativeApp';
import { createPath } from '@/utils/path';

gql`
  fragment HomeMyRevealedCellCount on RevealedCellCount {
    total
  }
`;

gql`
  fragment HomePageYellSummary on YellSummary {
    yellCount
    co2power
  }
`;

gql`
  fragment HomePageMyYellPointSummary on YellPointSummaryOutput {
    balance
    totalAmount
    amountOfThisYear
  }
`;

const HomePage: FC = () => {
  const router = useRouter();
  const { t } = useTranslation(['common', 'home']);
  const isNativeApp = useIsNativeApp();
  const handleSetting = useCallback(() => {
    isNativeApp
      ? window.location.assign(createPath({ path: Paths.v2Settings }))
      : router.push(createPath({ path: Paths.v2Settings }));
  }, [isNativeApp, router]);
  const {
    facilities,
    nfts,
    sumPlantCapacityNumber,
    yellCount,
    myRevealedCellsCount,
    allRevealedCellsCount,
    sumCo2Power,
    lastUpdatedAt,
    donationAmount,
    myDonationAmount,
    yellPointBalance,
    yellPointAmount,
    yellPointAnnual,
    co2PointBalance,
    co2PointAmount,
    co2PointAnnual,
    homeMyStampImgUrl,
  } = useHome();
  const co2 = 123456;
  const co2Size = co2.toLocaleString().length;

  return (
    <BaseBG position="relative">
      <HStack
        justifyContent="space-between"
        position="sticky"
        top={0}
        left={0}
        w="full"
        bg="white"
        py="4"
        px="6"
        zIndex={100}
      >
        <IconButton
          aria-label={t('common:action.open_to', {
            name: t('common:card'),
          })}
          icon={<SvgBtnCard />}
          onClick={() => console.log('click')}
          isDisabled
        />
        <IconButton
          aria-label={t('common:action.open_to', {
            name: t('common:setting'),
          })}
          icon={<SvgBtnSetting />}
          onClick={handleSetting}
        />
      </HStack>
      <VStack gap="6" px="6" w="full" position="relative" py="3">
        <VStack align="flex-start" w="full" gap="4" py="6">
          <Text fontSize="lg" fontWeight="medium" color="text.gray">
            {t('home:co2_reduction')}
          </Text>
          {sumCo2Power === undefined ? (
            <SkeletonText w="full" noOfLines={1} skeletonHeight="16" />
          ) : (
            <Text
              fontSize={co2Size > 10 ? '2xl' : co2Size > 7 ? '4xl' : '5xl'}
              fontWeight="extrabold"
              lineHeight={1}
            >
              {sumCo2Power?.toLocaleString()}
              <Text
                as="span"
                fontSize={co2Size > 10 ? 'xl' : co2Size > 7 ? '2xl' : '3xl'}
                ml="2"
              >
                kg
              </Text>
            </Text>
          )}
          {/* TODO: 増減率 */}
          {/* <HStack
            px="2"
            py="1"
            borderRadius="4px"
            color="text.darkGreen"
            bg="bg.lightGreen"
            whiteSpace="nowrap"
            fontSize="ml"
            spacing="2px"
          >
            <Text fontWeight="medium">+</Text>
            <Text fontWeight="bold">{rate.toLocaleString()}</Text>
            <Text fontWeight="medium">%</Text>
          </HStack> */}
        </VStack>
        <HStack gap="6" w="full" align="flex-start">
          <CertificateSummary />
          <Summary
            facilities={facilities}
            nfts={nfts}
            yellCount={yellCount}
            myRevealedCellsCount={myRevealedCellsCount}
            homeMyStampImgUrl={homeMyStampImgUrl}
          />
        </HStack>
        <PointCards
          yellPointBalance={yellPointBalance}
          yellPointAmount={yellPointAmount}
          yellPointAnnual={yellPointAnnual}
          co2PointBalance={co2PointBalance}
          co2PointAmount={co2PointAmount}
          co2PointAnnual={co2PointAnnual}
        />
        <InformationDetail
          facilities={facilities}
          nfts={nfts}
          sumPlantCapacityNumber={sumPlantCapacityNumber}
          myRevealedCellsCount={myRevealedCellsCount}
          allRevealedCellsCount={allRevealedCellsCount}
          donationAmount={donationAmount}
          myDonationAmount={myDonationAmount}
        />
        <Flex justify="flex-end" w="full">
          <Text fontSize="xs">{`${t(
            'home:last_update'
          )}: ${lastUpdatedAt}`}</Text>
        </Flex>
      </VStack>
    </BaseBG>
  );
};

export { HomePage };
