import Image from 'next/image';
import { useRef, useState } from 'react';

import { Card } from '@/components/common/Card/Card';
import { useResizeEvent } from '@/hooks/useResizeEvent';

interface Props {
  alt: string;
  src: string;
  shadow?: 'none' | 'medium' | 'heavy';
}

export const SquareImageCard = ({ alt, src, shadow }: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const [isError, setIsError] = useState(false);

  /**
   * Sets the height of the SquareImageCard element based on its current width.
   */
  const setHeight = () => {
    if (!ref.current) return;
    ref.current.style.height = `${ref.current.clientWidth}px`;
  };

  useResizeEvent(setHeight);

  return (
    <Card ref={ref} position="relative" shadow={shadow} noPadding>
      {isError ? null : (
        <Image
          alt={alt}
          src={src}
          style={{ objectFit: 'contain', borderRadius: '16px' }}
          fill
          onError={() => {
            setIsError(true);
          }}
        />
      )}
    </Card>
  );
};
