import React from 'react';

type Props = {
  color?: string;
};

function BtnCard(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill={props.color ?? 'currentColor'}
        d="M24.667 11.333a2.224 2.224 0 012.222 2.223v8.888a2.225 2.225 0 01-2.222 2.223H11.333a2.224 2.224 0 01-2.222-2.223v-8.888a2.225 2.225 0 012.222-2.223h13.334zm0-1.11H11.333A3.334 3.334 0 008 13.555v8.888a3.334 3.334 0 003.333 3.334h13.334A3.334 3.334 0 0028 22.444v-8.888a3.333 3.333 0 00-3.333-3.334zm0 3.888a.555.555 0 00-.556-.556h-3.333a.556.556 0 000 1.112h3.333a.556.556 0 00.556-.556zm0 2.222a.555.555 0 00-.556-.555h-3.333a.556.556 0 000 1.11h3.333a.556.556 0 00.556-.555zm0 2.222A.555.555 0 0024.11 18h-3.333a.556.556 0 000 1.111h3.333a.556.556 0 00.556-.556z"
      ></path>
    </svg>
  );
}

export const SvgBtnCard = React.memo(BtnCard);
