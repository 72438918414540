import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

function IconShortRibbon({ size = 16, color = 'currentColor' }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_5161_6908)">
        <path
          fill={color}
          d="M10.537 1.942a.943.943 0 01.942.942v12.172l-2.591-2.073a1.884 1.884 0 00-2.354 0l-2.592 2.073V2.884a.943.943 0 01.942-.942h5.653m0-.942H4.884A1.884 1.884 0 003 2.884v12.172a.943.943 0 001.53.736l2.592-2.073a.942.942 0 011.177 0l2.592 2.073a.943.943 0 001.53-.736V2.884A1.884 1.884 0 0010.537 1z"
        ></path>
        <path
          stroke={color}
          strokeWidth="0.68"
          d="M10.537 1.942a.943.943 0 01.942.942v12.172l-2.591-2.073a1.884 1.884 0 00-2.354 0l-2.592 2.073V2.884a.943.943 0 01.942-.942h5.653m0-.942H4.884A1.884 1.884 0 003 2.884v12.172a.943.943 0 001.53.736l2.592-2.073a.942.942 0 011.177 0l2.592 2.073a.943.943 0 001.53-.736V2.884A1.884 1.884 0 0010.537 1h0z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5161_6908">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export const SvgIconShortRibbon = React.memo(IconShortRibbon);
