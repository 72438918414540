import { Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  label: string;
  value: string;
}

export const VerticalItem: FC<Props> = ({ label, value }) => {
  return (
    <VStack
      minH="120px"
      gap="1"
      align="flex-start"
      transform="rotate(180deg)"
      lineHeight={1}
      style={{ writingMode: 'vertical-rl' }}
    >
      <Text fontSize="xs" color="text.gray" fontWeight="light">
        {label}
      </Text>
      <Text fontSize="sm" fontWeight="medium">
        {value}
      </Text>
    </VStack>
  );
};
