import { format, isBefore, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useContext, useEffect, useMemo, useState } from 'react';
import { gql } from 'urql';

import { AuthContext } from '@/components/context/AuthContext';
import {
  MyPagePowerDailyHistoriesDocument,
  MyPagePowerDailyHistoriesQuery,
  OrderType,
  WebMyNftsQuery,
  WebPowerDailyHistoryInput,
  useHomeMyCo2PointSummaryQuery,
  useHomeMyNumOfRevealedCellsQuery,
  useHomeMyStampImgQuery,
  useHomeMyYellPointSummaryQuery,
  useHomeNumOfRevealedCellsQuery,
  useWebDonationAmountQuery,
  useWebMyDonationAmountQuery,
  useWebMyNftsQuery,
  useHomeMyYellSummaryQuery,
} from '@/graphql/generated';
import { urqlClient } from '@/graphql/proxyClient';
import { dateToDateString } from '@/utils/date';
import { roundUpByDigit } from '@/utils/math';

gql`
  query homeMyNumOfRevealedCells {
    webMyNumOfRevealedCells {
      ...HomeMyRevealedCellCount
    }
  }
`;

gql`
  query homeNumOfRevealedCells {
    webNumOfRevealedCells
  }
`;

gql`
  query homeMyYellSummary {
    webMyYellSummary {
      ...HomePageYellSummary
    }
  }
`;

gql`
  query homeMyYellPointSummary {
    webMyYellPointSummary {
      ...HomePageMyYellPointSummary
    }
  }
`;

gql`
  fragment HomeMyCo2PointSummary on Co2PointSummaryOutput {
    totalAmount
    balance
    amountOfThisYear
  }
`;

gql`
  query homeMyCo2PointSummary {
    webMyCo2PointSummary {
      ...HomeMyCo2PointSummary
    }
  }
`;

gql`
  fragment HomeMyStampImg on MyRevealedCellPaginationOutput {
    data {
      imageURL
    }
  }
`;

gql`
  query homeMyStampImg($input: MyRevealedCellPaginationInput!) {
    webMyRevealCellsPagination(input: $input) {
      ...HomeMyStampImg
    }
  }
`;

type GroupsByContributorProps = {
  id: string;
  count: number;
  product: WebMyNftsQuery['webMyNfts'][0]['lot']['product'];
  updatedAt: Date;
};

const useHome = () => {
  const { currentUser } = useContext(AuthContext);
  const client = urqlClient();
  const currentDate = useMemo(() => new Date(), []);
  const startDate = useMemo(() => {
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
  }, [currentDate]);
  const endToday = useMemo(() => {
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      23,
      59
    );
  }, [currentDate]);
  const oneYearAgoDate = new Date(startDate);
  oneYearAgoDate.setFullYear(startDate.getFullYear() - 1);
  const oneMonthAgoDate = new Date(startDate);
  oneMonthAgoDate.setMonth(startDate.getMonth() - 1);
  const oneWeekAgoDate = new Date(startDate);
  oneWeekAgoDate.setDate(startDate.getDate() - 7);
  const [endAt] = useState<Date>(currentDate);
  const [lastUpdatedAt, setLastUpdatedAt] = useState<string>('');
  const [myNftsData] = useWebMyNftsQuery({
    pause: !currentUser,
  });
  const [yellSummaryData] = useHomeMyYellSummaryQuery({
    pause: !currentUser,
  });
  const [myRevealedCells] = useHomeMyNumOfRevealedCellsQuery({
    pause: !currentUser,
  });
  const [revealedCells] = useHomeNumOfRevealedCellsQuery();
  const [webDonationAmountData] = useWebDonationAmountQuery();
  const [webMyDonationAmountData] = useWebMyDonationAmountQuery({
    pause: !currentUser,
  });
  const [yellPointSummaryData] = useHomeMyYellPointSummaryQuery({
    pause: !currentUser,
  });

  const [yellPointSummaryAnnualData] = useHomeMyYellPointSummaryQuery({
    pause: !currentUser,
  });

  const [co2PointSummaryData] = useHomeMyCo2PointSummaryQuery({
    pause: !currentUser,
  });

  const [homeMyStampImg] = useHomeMyStampImgQuery({
    variables: {
      input: {
        offset: 0,
        limit: 1,
        orderBy: OrderType.Desc,
      },
    },
  });
  const groupsByContributor = useMemo(
    () =>
      myNftsData.data?.webMyNfts
        ? myNftsData.data.webMyNfts.reduce(
            (result: GroupsByContributorProps[], cur) => {
              const element = result.find(
                (value) => value.id === cur.lot.product.targetObject.id
              );
              if (element) {
                element.count++;
                /** 一番古いnft.updatedAt */
                element.updatedAt = isBefore(
                  new Date(element.updatedAt),
                  new Date(cur.updatedAt)
                )
                  ? new Date(element.updatedAt)
                  : new Date(cur.updatedAt);
              } else {
                result.push({
                  id: cur.lot.product.targetObject.id,
                  count: 1,
                  product: cur.lot.product,
                  updatedAt: new Date(cur.updatedAt),
                });
              }
              return result;
            },
            []
          )
        : undefined,
    [myNftsData]
  );

  useEffect(() => {
    if (!groupsByContributor) return;
    const getSumCo2PowerPromises = Promise.all(
      groupsByContributor.map(async (contributor) => {
        const powerTotalId =
          contributor.product.targetObject.__typename === 'Contributor'
            ? contributor.product.targetObject.powerTotal?.id
            : undefined;
        const result = await client.query<
          MyPagePowerDailyHistoriesQuery,
          { input: WebPowerDailyHistoryInput }
        >(MyPagePowerDailyHistoriesDocument, {
          input: {
            powerTotalId: powerTotalId || '',
            startAt: dateToDateString(startOfDay(contributor.updatedAt)),
            endAt: dateToDateString(endAt),
          },
        });

        if (result.data?.webPowerDailyHistories) {
          const sum = result.data.webPowerDailyHistories.reduce(
            (sum, arr) => sum + Number(arr.co2Power),
            0
          );
          return {
            sum,
            updatedAt:
              result.data.webPowerDailyHistories[0]?.powerTotal.updatedAt,
          };
        }
        return { sum: 0, updatedAt: new Date() };
      })
    );

    getSumCo2PowerPromises.then((sums) => {
      const latestDate = sums.reduce((acc, sum) => {
        return isBefore(new Date(acc), new Date(sum.updatedAt))
          ? new Date(sum.updatedAt)
          : new Date(acc);
      }, new Date(1999, 1, 1));
      setLastUpdatedAt(
        format(utcToZonedTime(latestDate, 'JST'), 'yyyy/MM/dd HH:mm')
      );
    });
  }, [client, endAt, groupsByContributor]);

  const facilities = useMemo(
    () => groupsByContributor?.length,
    [groupsByContributor]
  );

  const nfts = useMemo(() => myNftsData.data?.webMyNfts.length, [myNftsData]);

  const sumPlantCapacityNumber = useMemo(
    () =>
      groupsByContributor?.reduce((sum, arr: GroupsByContributorProps) => {
        if (arr.product.targetObject.__typename === 'Contributor') {
          return (
            sum +
            arr.product.targetObject.plantCapacityNumber *
              arr.product.targetObject.co2PowerPerUnit *
              (arr.product.targetObject.daylightHours ?? 0)
          );
        }
        return sum;
      }, 0),
    [groupsByContributor]
  );

  const yellCount = useMemo(
    () => yellSummaryData.data?.webMyYellSummary.yellCount,
    [yellSummaryData]
  );

  const myRevealedCellsCount = useMemo(
    () => myRevealedCells.data?.webMyNumOfRevealedCells.total,
    [myRevealedCells.data?.webMyNumOfRevealedCells.total]
  );

  const allRevealedCellsCount = useMemo(
    () => revealedCells.data?.webNumOfRevealedCells,
    [revealedCells.data?.webNumOfRevealedCells]
  );

  const donationAmount = useMemo(
    () => webDonationAmountData.data?.webDonationAmount,
    [webDonationAmountData]
  );

  const myDonationAmount = useMemo(
    () => webMyDonationAmountData.data?.webMyDonationAmount,
    [webMyDonationAmountData]
  );

  const yellPointBalance = useMemo(
    () => yellPointSummaryData.data?.webMyYellPointSummary.balance,
    [yellPointSummaryData]
  );

  const yellPointAmount = useMemo(
    () => yellPointSummaryData.data?.webMyYellPointSummary.totalAmount,
    [yellPointSummaryData]
  );

  const yellPointAnnual = useMemo(
    () => yellPointSummaryAnnualData.data?.webMyYellPointSummary.totalAmount,
    [yellPointSummaryAnnualData.data?.webMyYellPointSummary.totalAmount]
  );

  const sumCo2Power = useMemo(
    () =>
      yellSummaryData.data?.webMyYellSummary.co2power
        ? roundUpByDigit(yellSummaryData.data.webMyYellSummary.co2power, 0)
        : undefined,
    [yellSummaryData]
  );

  const co2PointBalance = useMemo(
    () => co2PointSummaryData.data?.webMyCo2PointSummary.balance,
    [co2PointSummaryData]
  );

  const co2PointAmount = useMemo(
    () => co2PointSummaryData.data?.webMyCo2PointSummary.totalAmount,
    [co2PointSummaryData]
  );

  const co2PointAnnual = useMemo(
    () => co2PointSummaryData.data?.webMyCo2PointSummary.amountOfThisYear,
    [co2PointSummaryData]
  );
  const homeMyStampImgUrl = useMemo(
    () =>
      homeMyStampImg.fetching
        ? '/stamp_generating.webp'
        : homeMyStampImg.data?.webMyRevealCellsPagination.data &&
          homeMyStampImg.data.webMyRevealCellsPagination.data[0] &&
          homeMyStampImg.data.webMyRevealCellsPagination.data[0].imageURL
        ? `${homeMyStampImg.data.webMyRevealCellsPagination.data[0].imageURL}/resized480.png`
        : undefined,
    [homeMyStampImg]
  );

  return {
    facilities,
    nfts,
    sumPlantCapacityNumber,
    yellCount,
    myRevealedCellsCount,
    allRevealedCellsCount,
    sumCo2Power,
    lastUpdatedAt,
    donationAmount,
    myDonationAmount,
    yellPointBalance,
    yellPointAmount,
    yellPointAnnual,
    co2PointBalance,
    co2PointAmount,
    co2PointAnnual,
    currentDate,
    homeMyStampImgUrl,
  };
};

export default useHome;
