import React from 'react';

interface Props extends Pick<React.SVGProps<SVGSVGElement>, 'color'> {
  size?: number;
}

function IconCheckPoint(props: Props) {
  return (
    <svg
      width={props.size ?? '40'}
      height={props.size ?? '40'}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.5" cy="20" r="20" fill="white" />
      <rect
        x="17.6428"
        y="24"
        width="2.85714"
        height="10.2857"
        fill={props.color ?? 'currentColor'}
      />
      <path
        d="M25.6428 8.57144H20.5H17.6428V5.71429H25.6428C30.6923 5.71429 34.7857 9.80769 34.7857 14.8572C34.7857 19.9066 30.6923 24 25.6428 24H17.6428V21.1429H20.5H25.6428C29.1143 21.1429 31.9285 18.3287 31.9285 14.8572C31.9285 11.3856 29.1143 8.57144 25.6428 8.57144Z"
        fill={props.color ?? 'currentColor'}
      />
      <circle
        cx="32.7856"
        cy="32.2856"
        r="2"
        fill={props.color ?? 'currentColor'}
      />
    </svg>
  );
}

export const SvgIconCheckPoint = React.memo(IconCheckPoint);
