import React from 'react';

type Props = {
  fill?: string;
  size?: string;
};
function IconPerformance(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '24'}
      height={props.size ?? '24'}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.fill || '#020202'}
        d="M20.875 10.476a9.005 9.005 0 10-10.4 10.399 8.995 8.995 0 0010.4-10.4zm-2.206 5.926a1.942 1.942 0 01-2.502.628 8.943 8.943 0 00-8.333 0 1.943 1.943 0 01-2.504-.63 7.953 7.953 0 01-1.236-5.64A8.001 8.001 0 0120 12a7.923 7.923 0 01-1.331 4.402zm-1.815-9.256a.5.5 0 010 .707l-3.903 3.903c.027.079.043.16.049.244a1 1 0 11-1-1 .962.962 0 01.244.049l3.902-3.903a.5.5 0 01.707 0z"
      ></path>
    </svg>
  );
}

export const SvgIconPerformance = React.memo(IconPerformance);
