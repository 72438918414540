import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
} from '@chakra-ui/react';
import { FC } from 'react';

interface IconButtonProps
  extends Pick<
    ChakraIconButtonProps,
    'icon' | 'variant' | 'colorScheme' | 'size' | 'onClick' | 'isDisabled'
  > {
  'aria-label': string;
}

type Props = IconButtonProps;
const IconButton: FC<Props> = ({
  icon,
  variant = 'iconPrimary',
  colorScheme = 'button.gray',
  size = 'default',
  'aria-label': ariaLabel,
  onClick,
  isDisabled = false,
}) => {
  return (
    <ChakraIconButton
      aria-label={ariaLabel}
      icon={icon}
      onClick={onClick}
      size={size}
      variant={variant}
      colorScheme={colorScheme}
      isDisabled={isDisabled}
    />
  );
};

export type { IconButtonProps };
export { IconButton };
