import { HStack, SkeletonText, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

import { useTranslation } from '@/app/i18n/client';
import { SvgIconCheckPoint } from '@/components/assets/IconCheckPoint';
import { SvgIconCo2Point } from '@/components/assets/IconCo2Point';
import { Card } from '@/components/common/Card/Card';
import { InformationButton } from '@/components/common/InformationButton';

interface Props {
  type: 'co2' | 'check';
  point?: number;
  lifetimePoint?: number;
  annualPoint?: number;
}

export const PointCard: FC<Props> = ({
  type,
  point,
  lifetimePoint,
  annualPoint,
}) => {
  const { t } = useTranslation(['common', 'home', 'dictionary']);
  const pointSize = point?.toLocaleString().length || 0;
  const lifetimeAndAnnualPointSize =
    (lifetimePoint?.toLocaleString().length || 0) +
    (annualPoint?.toLocaleString().length || 0);

  return (
    <Card
      w={['full', '450px']}
      minW="320px"
      bg={type === 'co2' ? 'bg.green' : 'white'}
      color={type === 'co2' ? 'white' : 'black'}
    >
      <VStack gap="36px">
        <HStack w="full" justify="space-between">
          <Text fontSize="xl" fontWeight="medium">
            {type === 'co2'
              ? t('home:co2_point', 'CO2ポイント')
              : t('home:check_point', 'エールポイント')}
          </Text>
          {type === 'co2' ? (
            <InformationButton
              aria-label={t('common:about', {
                word: t('dictionary:co2_points', 'CO2ポイント'),
              })}
              title={t('dictionary:co2_points', 'CO2ポイント')}
              subTitle={'[co2 points]'}
              description={t(
                'dictionary:co2_points_gloss',
                '設備のCO2削減を「チェック」してくれたら、そのお礼にポイントをプレゼントしているよ。\nCO2ポイントは、2つめのミッション「HOSHIをグリーンに」でマスをグリーンにするのに使ってね。\nチェックして、CO2ポイントをためて、HOSHIを美しいグリーンにしていこう！\n\n\n【計算方法】\n・「チェック」をしたときにもらえます。\n・チェックした日に、対象の設備が削減したCO2量と同等のポイントがもらえます。（1kg = 1point）\n・設備ごとではなく、NFTごとにもらえます。'
              )}
              key="checked-points-description-dialog"
              variant="ghost"
              colorScheme="white"
            />
          ) : (
            <InformationButton
              aria-label={t('common:about', {
                word: t('dictionary:checked_points', 'エールポイント'),
              })}
              title={t('dictionary:checked_points', 'エールポイント')}
              subTitle={'[checked points]'}
              description={t(
                'dictionary:checked_points_gloss',
                '設備のCO2削減を「チェック」してくれたら、そのお礼にポイントをプレゼントしているよ。\n\nチェックポイントは、楽天ポイントに交換したり、他の設備NFTを購入することに使ってね。\n\n\n【計算方法】\n・当日にチェックすれば2ポイント、それ以外は1ポイントもらえます。\n・チェック数30回まではNFT販売額の10%を30等分したポイント数が加算されてもらえます。\n（例）1,000円のNFTの場合、当日にチェックすると2ポイント+3ポイント=5ポイントゲット！'
              )}
              key="checked-points-description-dialog"
              variant="ghost"
              colorScheme="black"
            />
          )}
        </HStack>
        <HStack>
          {type === 'co2' ? (
            <SvgIconCo2Point size="40" />
          ) : (
            <SvgIconCheckPoint />
          )}
          {point ? (
            <Text
              fontSize={
                pointSize > 13 ? '2xl' : pointSize > 10 ? '3xl' : '52px'
              }
              fontWeight="medium"
              lineHeight="78px"
            >
              {Number(point).toLocaleString()}
            </Text>
          ) : (
            <SkeletonText w="150px" noOfLines={1} skeletonHeight="12" />
          )}
        </HStack>
        <HStack
          gap="2"
          fontSize={lifetimeAndAnnualPointSize > 19 ? 'xxs' : 'xs'}
        >
          <HStack gap="0.5">
            <Text>{t('home:lifetime', '生涯')}</Text>
            {lifetimePoint === undefined ? (
              <SkeletonText w="60px" noOfLines={1} skeletonHeight="4" />
            ) : (
              <Text fontWeight="bold">
                {Number(lifetimePoint).toLocaleString()}
              </Text>
            )}
          </HStack>
          <HStack gap="0.5">
            <Text>{t('home:annual', '年間')}</Text>
            {annualPoint === undefined ? (
              <SkeletonText w="60px" noOfLines={1} skeletonHeight="4" />
            ) : (
              <Text fontWeight="bold">
                {Number(annualPoint).toLocaleString()}
              </Text>
            )}
          </HStack>
        </HStack>
      </VStack>
    </Card>
  );
};
