import { HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { AnimatePresence, m } from 'framer-motion';
import {
  ComponentProps,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTranslation } from '@/app/i18n/client';
import { SvgBtnClose } from '@/components/assets/BtnClose';
import { BaseBG } from '@/components/common/BaseBG/BaseBG';
import { IconButton } from '@/components/common/IconButton/IconButton';
import { PointCard } from '@/components/common/PointCard/PointCard';
import { PointDetail } from '@/components/pages/V2/Home/PointDetail';

type Props = {
  yellPointBalance?: number;
  yellPointAmount?: number;
  yellPointAnnual?: number;
  co2PointBalance?: number;
  co2PointAmount?: number;
  co2PointAnnual?: number;
};

export const PointCards: FC<Props> = ({
  yellPointBalance,
  yellPointAmount,
  yellPointAnnual,
  co2PointBalance,
  co2PointAmount,
  co2PointAnnual,
}) => {
  const items: Record<string, ComponentProps<typeof PointCard>> = {
    co2: {
      type: 'co2',
      point: co2PointBalance,
      lifetimePoint: co2PointAmount,
      annualPoint: co2PointAnnual,
    },
    check: {
      type: 'check',
      point: yellPointBalance,
      lifetimePoint: yellPointAmount,
      annualPoint: yellPointAnnual,
    },
  };

  const [selectedType, setSelectedType] = useState<'co2' | 'check' | null>(
    null
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation(['common']);

  const ref = useRef<HTMLDialogElement>(null);

  const item = useMemo<ComponentProps<typeof PointCard> | null>(
    () => (selectedType === null ? null : items[selectedType]),
    [selectedType]
  );

  const handleOpen = (type: 'co2' | 'check') => {
    setSelectedType(type);
    onOpen();
  };

  const handleClose = () => {
    setSelectedType(null);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      if (ref.current?.hasAttribute('open')) {
        return;
      }
      ref.current?.showModal();
    } else {
      if (!ref.current?.hasAttribute('open')) {
        return;
      }
      ref.current?.close();
    }
  }, [isOpen]);

  return (
    <>
      <VStack position="relative" w="full">
        {Object.values(items).map((item, index) => (
          <m.div
            key={item.type}
            layoutId={item.type}
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: index === 0 ? undefined : '-162px',
            }}
            onClick={() => {
              handleOpen(item.type);
            }}
          >
            <PointCard {...item} />
          </m.div>
        ))}
      </VStack>
      <AnimatePresence>
        <m.dialog
          key="dialog"
          ref={ref}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: isOpen ? 1 : 0 }}
          exit={{ opacity: 0 }}
          onClose={handleClose}
          style={{
            position: 'fixed',
            display: isOpen ? 'block' : 'none',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            padding: 0,
            margin: 0,
            overscrollBehavior: 'contain',
            zIndex: 100,
          }}
        >
          <BaseBG h="full" overflow="auto">
            <VStack w={{ base: 'full', sm: 'full', md: '400px' }} mx="auto">
              <VStack gap="6" p="6" w="full">
                <HStack justifyContent="flex-start" w="full">
                  <IconButton
                    aria-label={t('common:action.close')}
                    icon={<SvgBtnClose />}
                    onClick={handleClose}
                  />
                </HStack>
                {item !== null && (
                  <m.div
                    key={item.type}
                    layoutId={item.type}
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                    onClick={handleClose}
                  >
                    <PointCard {...item} />
                  </m.div>
                )}
                <m.div
                  initial={{ y: 600 }}
                  animate={{ y: 0 }}
                  exit={{ y: 600 }}
                  transition={{ duration: 0.5, ease: 'easeOut' }}
                  style={{ width: '100%' }}
                >
                  {selectedType === null ? null : (
                    <PointDetail type={selectedType} />
                  )}
                </m.div>
              </VStack>
            </VStack>
          </BaseBG>
        </m.dialog>
      </AnimatePresence>
    </>
  );
};
