import { Flex } from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  color: string;
  children: React.ReactNode;
}

export const PointIcon: FC<Props> = ({ color, children }) => {
  return (
    <Flex
      minW="48px"
      h="48px"
      borderWidth="3px"
      borderRadius="8px"
      borderColor={color}
      bg="bg.primary"
      align="center"
      justify="center"
      boxShadow={`0px 4px 16px 0px ${color}3D`}
    >
      {children}
    </Flex>
  );
};
