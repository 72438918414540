import { Box, VStack } from '@chakra-ui/react';
import { FC } from 'react';

import { VerticalItem } from '@/components/pages/V2/Home/VerticalItem';

export const CertificateSummary: FC = () => {
  return (
    <Box
      minW="80px"
      w="80px"
      h="480px"
      ml="-6"
      borderRightRadius="16px"
      boxShadow="0px 4px 16px 0px #0000003D"
      bg="linear-gradient(0deg, #E9E9E9, #E9E9E9), radial-gradient(57.68% 39.95% at 16.23% 82.64%, rgba(254, 254, 254, 0.6) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(68.21% 47.24% at 80.58% 15.22%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);"
      cursor="not-allowed"
      onClick={() => console.log('click')} // TODO: open modal
    >
      <VStack
        w="full"
        h="full"
        p="5"
        gap="4"
        bgImage='url("/grid_bg.svg")'
        justifyContent="space-between"
        alignItems="end"
      >
        <VerticalItem label="" value="COMING SOON..." />
        <VerticalItem label="" value="capture.x - Status Card" />
      </VStack>
    </Box>
  );
};
