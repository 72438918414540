import React from 'react';

interface Props {
  color?: string;
}

function BtnClose(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill={props.color ?? 'currentColor'}
        d="M19.189 18l3.565-3.565a.84.84 0 10-1.189-1.189L18 16.811l-3.565-3.565a.841.841 0 00-1.189 1.189L16.811 18l-3.565 3.565a.841.841 0 001.189 1.189L18 19.188l3.565 3.566a.84.84 0 001.189-1.189L19.188 18z"
      ></path>
    </svg>
  );
}

export const SvgBtnClose = React.memo(BtnClose);
