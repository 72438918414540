import { useContext, useEffect, useMemo, useRef } from 'react';

import { AuthContext } from '@/components/context/AuthContext';
import { useMyCo2PointHistoriesQuery } from '@/graphql/generated';

interface Props {
  isLastPage: boolean;
  variables: { limit: number; offset: number };
  onLoadMore: (offset: number) => void;
}

export const usePointHistories = ({
  variables,
  isLastPage,
  onLoadMore,
}: Props) => {
  const { currentUser } = useContext(AuthContext);
  const loadingRef = useRef<HTMLDivElement>(null);

  const [{ data, fetching }] = useMyCo2PointHistoriesQuery({
    variables: {
      input: variables,
    },
    pause: !currentUser,
  });

  const hasNextPage = data?.webMyCo2PointHistoriesPagination.hasNextPage;

  const histories = useMemo(() => {
    return (
      data?.webMyCo2PointHistoriesPagination.data.map(
        ({ id, type, amount, createdAt }) => ({
          id,
          type,
          amount,
          createdAt: new Date(createdAt),
        })
      ) || undefined
    );
  }, [data?.webMyCo2PointHistoriesPagination.data]);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && isLastPage && hasNextPage) {
        onLoadMore(variables.limit + variables.offset);
      }
    });

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => observer.disconnect();
  }, [onLoadMore, variables.limit, variables.offset, isLastPage, hasNextPage]);

  return {
    fetching,
    hasNextPage,
    loadingRef,
    histories,
  };
};
