import { Box, Flex } from '@chakra-ui/react';
import { AnimatePresence, PanInfo, m } from 'framer-motion';
import { FC, ReactNode, useEffect, useRef } from 'react';

const VELOCITY_OFFSET = 80;
const OFFSET = 200;

type PropsHalfScreenModal = {
  children: ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  key: string;
};
const HalfScreenModal: FC<PropsHalfScreenModal> = ({
  children,
  isOpen,
  handleClose,
  key,
}) => {
  const ref = useRef<HTMLDialogElement>(null);

  const onDragEnd = (
    _event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    const shouldClose =
      info.velocity.y > VELOCITY_OFFSET ||
      (info.velocity.y >= 0 && info.offset.y > OFFSET);

    if (shouldClose) {
      handleClose();
    }
  };
  useEffect(() => {
    if (isOpen) {
      if (ref.current?.hasAttribute('open')) {
        return;
      }
      ref.current?.showModal();
    } else {
      if (!ref.current?.hasAttribute('open')) {
        return;
      }
      // アニメーション終了後にクローズ
      const modalCloseTimeoutId = setTimeout(() => {
        ref.current?.close();
      }, 300);
      return () => clearTimeout(modalCloseTimeoutId);
    }
  }, [isOpen]);
  const open = {
    top: '50vh',
  };
  const close = {
    top: '100vh',
  };
  return (
    <AnimatePresence>
      <m.dialog
        drag="y"
        dragConstraints={{ top: 0 }}
        dragElastic={{ top: 0 }}
        dragSnapToOrigin={true}
        dragMomentum={false}
        onDragEnd={onDragEnd}
        key={key}
        ref={ref}
        transition={{
          top: { duration: 0.3, ease: 'easeOut' },
        }}
        initial={{ top: '100vh' }}
        animate={isOpen ? open : close}
        exit={{ top: '100vh' }}
        onClose={(e) => {
          e.stopPropagation();
          handleClose();
        }}
        onClick={(e) => {
          // safariでイベントの伝播を防ぐため
          e.stopPropagation();
          const el = e.target as HTMLElement;
          if (el.tagName === 'DIALOG') {
            // backdrop
            handleClose();
          }
        }}
        style={{
          position: 'fixed',
          left: 0,
          width: 'fit-content',
          height: '100%',
          maxWidth: '100%',
          maxHeight: '100%',
          padding: 0,
          margin: 0,
          overscrollBehavior: 'contain',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          filter: 'drop-shadow(0px -4px 8px rgba(0,0,0,0.16))',
          overflow: 'hidden',
          zIndex: 100,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Flex justify="center" align="center" pt="1">
          <Box w="36px" h="5px" bg="#3C3C434D" borderRadius="2.5px" />
        </Flex>
        {children}
      </m.dialog>
    </AnimatePresence>
  );
};

export { HalfScreenModal };
export type { PropsHalfScreenModal };
