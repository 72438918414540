import {
  HStack,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  icon?: JSX.Element;
  title?: string;
  titleFontSize?: 'md' | 'ml';
  titleFontWeight?: 'medium' | 'bold';
  items: Array<{ label: string | number; value?: string | number | null }>;
}

export const ListTable: FC<Props> = ({
  icon,
  title,
  titleFontSize = 'md',
  titleFontWeight = 'medium',
  items,
}) => {
  return (
    <VStack gap="4" w="full">
      {typeof title === 'string' ? (
        <HStack gap="1" justify="flex-start" w="full" align="center">
          {icon}
          <Text
            fontSize={titleFontSize}
            fontWeight={titleFontWeight}
            lineHeight={1.2}
          >
            {title}
          </Text>
        </HStack>
      ) : null}
      <TableContainer
        w="full"
        color="text.gray"
        fontWeight="medium"
        fontSize="sm"
      >
        <Table variant="simple">
          <Tbody>
            {items.map((item) => (
              <Tr key={item.label}>
                <Td pl="0">{item.label}</Td>
                <Td
                  isNumeric
                  pr="0"
                  whiteSpace="pre-wrap"
                  wordBreak="break-word"
                >
                  {item.value ?? <Skeleton w="80px" h="14px" ml="auto" />}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};
