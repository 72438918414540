import { addDays, addMinutes, format, isSameDay, setHours } from 'date-fns';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { gql } from 'urql';

import { AuthContext } from '@/components/context/AuthContext';
import {
  usePointDetailMyCo2PointSummaryQuery,
  PointDetailMyCo2PointSummaryQuery,
  usePointDetailMyYellPointSummaryQuery,
} from '@/graphql/generated';
import { dateToDateString } from '@/utils/date';

gql`
  query pointDetailMyYellSummary {
    webMyYellSummary {
      ...HomePageYellSummary
    }
  }
`;

gql`
  query pointDetailMyYellPointSummary {
    webMyYellPointSummary {
      ...HomePageMyYellPointSummary
    }
  }
`;

gql`
  fragment HomePageMyCo2PointSummary on Co2PointSummaryOutputForGraph {
    co2PointHistories {
      id
      balance
      targetDate
    }
    amountOfThisYear
    totalAmount
    balance
  }
`;

gql`
  query pointDetailMyCo2PointSummary(
    $historyStartDate: DateTime!
    $historyEndDate: DateTime!
  ) {
    webMyCo2PointSummaryForGraph(
      historyStartDate: $historyStartDate
      historyEndDate: $historyEndDate
    ) {
      ...HomePageMyCo2PointSummary
    }
  }
`;

export const PAGE_SIZE = 10 as const;
type Term = 'day' | 'week' | 'month' | 'year' | 'all';
const usePointDetail = () => {
  const { currentUser } = useContext(AuthContext);
  const [activeTerm, setActiveTerm] = useState<Term>('day');
  const currentDate = useMemo(() => new Date(), []);
  const [dailyStartDate] = useState(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    )
  );
  const [historyStartDate, setHistoryStartDate] =
    useState<Date>(dailyStartDate);

  const [chartAreaData, setChartAreaData] = useState<
    | PointDetailMyCo2PointSummaryQuery['webMyCo2PointSummaryForGraph']['co2PointHistories']
    | null
    | undefined
  >();
  const [pageVariables, setPageVariables] = useState([
    { limit: PAGE_SIZE, offset: 1 },
  ]);

  const [co2PointSummaryData] = usePointDetailMyCo2PointSummaryQuery({
    variables: {
      historyStartDate: dateToDateString(historyStartDate),
      historyEndDate: dateToDateString(currentDate),
    },
    pause: !currentUser,
  });

  const [yellPointSummaryData] = usePointDetailMyYellPointSummaryQuery({
    pause: !currentUser,
  });

  const yellPointAmountOfThisYear = useMemo(
    () => yellPointSummaryData.data?.webMyYellPointSummary.amountOfThisYear,
    [yellPointSummaryData]
  );

  const co2PointAmountOfThisYear = useMemo(
    () =>
      co2PointSummaryData.data?.webMyCo2PointSummaryForGraph.amountOfThisYear,
    [co2PointSummaryData]
  );

  const co2PointTotalBalance = useMemo(
    () => co2PointSummaryData.data?.webMyCo2PointSummaryForGraph.balance,
    [co2PointSummaryData.data?.webMyCo2PointSummaryForGraph.balance]
  );

  const co2PointAllAmount = useMemo(
    () => co2PointSummaryData.data?.webMyCo2PointSummaryForGraph.totalAmount,
    [co2PointSummaryData.data?.webMyCo2PointSummaryForGraph.totalAmount]
  );

  const onCo2PointAll = useCallback(() => {
    setHistoryStartDate(new Date('2023-03-01'));
    setActiveTerm('all');
  }, []);

  const onCo2PointYear = useCallback(() => {
    const yearlyStartDate = new Date(dailyStartDate);
    yearlyStartDate.setFullYear(dailyStartDate.getFullYear() - 1);
    setHistoryStartDate(new Date(yearlyStartDate));
    setActiveTerm('year');
  }, [dailyStartDate]);

  const onCo2PointMonth = useCallback(() => {
    const monthlyStartDate = new Date(dailyStartDate);
    monthlyStartDate.setMonth(dailyStartDate.getMonth() - 1);
    setHistoryStartDate(new Date(monthlyStartDate));
    setActiveTerm('month');
  }, [dailyStartDate]);

  const onCo2PointWeek = useCallback(() => {
    const weeklyStartDate = new Date(dailyStartDate);
    weeklyStartDate.setDate(dailyStartDate.getDate() - 7);
    setHistoryStartDate(new Date(weeklyStartDate));
    setActiveTerm('week');
  }, [dailyStartDate]);

  const onCo2PointToday = useCallback(() => {
    setHistoryStartDate(dailyStartDate);
    setActiveTerm('day');
  }, [dailyStartDate]);

  const yellPointTotalBalance = useMemo(
    () => yellPointSummaryData.data?.webMyYellPointSummary.balance,
    [yellPointSummaryData.data?.webMyYellPointSummary.balance]
  );

  const yellPointAllAmount = useMemo(
    () => yellPointSummaryData.data?.webMyYellPointSummary.totalAmount,
    [yellPointSummaryData.data?.webMyYellPointSummary.totalAmount]
  );

  const onYellPointAll = useCallback(() => {
    setHistoryStartDate(new Date('2023-03-01'));
    setActiveTerm('all');
  }, []);

  const onYellPointYear = useCallback(() => {
    const yearlyStartDate = new Date(dailyStartDate);
    yearlyStartDate.setFullYear(dailyStartDate.getFullYear() - 1);
    setHistoryStartDate(new Date(yearlyStartDate));
    setActiveTerm('year');
  }, [dailyStartDate]);

  const onYellPointMonth = useCallback(() => {
    const monthlyStartDate = new Date(dailyStartDate);
    monthlyStartDate.setMonth(dailyStartDate.getMonth() - 1);
    setHistoryStartDate(new Date(monthlyStartDate));
    setActiveTerm('month');
  }, [dailyStartDate]);

  const onYellPointWeek = useCallback(() => {
    const weeklyStartDate = new Date(dailyStartDate);
    weeklyStartDate.setDate(dailyStartDate.getDate() - 7);
    setHistoryStartDate(new Date(weeklyStartDate));
    setActiveTerm('week');
  }, [dailyStartDate]);

  const onYellPointToday = useCallback(() => {
    setHistoryStartDate(dailyStartDate);
    setActiveTerm('day');
  }, [dailyStartDate]);

  useEffect(() => {
    if (co2PointSummaryData.data) {
      setChartAreaData(
        co2PointSummaryData.data.webMyCo2PointSummaryForGraph.co2PointHistories
      );
    }
  }, [co2PointSummaryData]);

  const chartMemo = useMemo(() => {
    if (!chartAreaData) return [];

    const generateDataForDay = (date: Date) => {
      const chartDataArr: {
        position: string;
        value: number;
      }[] = [];
      Array.from({ length: 24 }, (_, index) => {
        const hourStart = setHours(date, index);
        const hourEnd = setHours(date, index + 1);

        const relevantData = chartAreaData.filter(
          (history) =>
            new Date(history.targetDate) >= hourStart &&
            new Date(history.targetDate) < hourEnd
        );

        const currentBalance =
          relevantData.at(-1) === undefined
            ? chartDataArr.at(-1)?.value
            : relevantData.at(-1)?.balance;

        chartDataArr.push({
          position: format(addMinutes(hourStart, 30), 'yyyy/MM/dd HH:mm'),
          value: currentBalance ?? 0,
        });
      });
      return chartDataArr;
    };

    const generateDataForPeriod = (
      startDate: Date,
      endDate: Date,
      step: number
    ) => {
      const chartDataArr: {
        position: string;
        value: number;
      }[] = [];

      for (
        let currentDate = startDate;
        currentDate <= endDate;
        currentDate = addDays(currentDate, step)
      ) {
        const relevantData = chartAreaData.filter((history) =>
          isSameDay(new Date(history.targetDate), currentDate)
        );

        const currentBalance =
          relevantData.at(-1) === undefined
            ? chartDataArr.at(-1)?.value
            : relevantData.at(-1)?.balance;

        chartDataArr.push({
          position: format(currentDate, 'yyyy/MM/dd'),
          value: currentBalance ?? 0,
        });
      }

      return chartDataArr;
    };

    switch (activeTerm) {
      case 'day':
        const dayData = generateDataForDay(dailyStartDate);
        return dayData;
      case 'week':
      case 'month':
      case 'year':
      case 'all':
        const data = generateDataForPeriod(historyStartDate, dailyStartDate, 1);
        return data;

      default:
        return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartAreaData]);

  return {
    co2PointAllAmount,
    co2PointTotalBalance,
    onCo2PointAll,
    onCo2PointYear,
    onCo2PointMonth,
    onCo2PointWeek,
    onCo2PointToday,
    yellPointAllAmount,
    yellPointTotalBalance,
    onYellPointAll,
    onYellPointYear,
    onYellPointMonth,
    onYellPointWeek,
    onYellPointToday,
    currentDate,
    activeTerm,
    chartMemo,
    yellPointAmountOfThisYear,
    co2PointAmountOfThisYear,
    pageVariables,
    setPageVariables,
  };
};

export default usePointDetail;
