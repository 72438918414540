import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';

interface Props extends BoxProps {
  children: React.ReactNode;
}

export const BaseBG: FC<Props> = ({ children, w = 'full', ...props }) => {
  return (
    <Box
      {...props}
      background="radial-gradient(43.32% 24% at 10.43% 45.54%, rgba(64, 133, 214, 0.12) 0%, rgba(64, 133, 214, 0) 100%),
radial-gradient(47.2% 24.99% at 97.33% 40.55%, rgba(244, 80, 208, 0.12) 0%, rgba(244, 80, 208, 0) 100%),
radial-gradient(41.04% 16.49% at 47.33% 16.43%, rgba(244, 208, 80, 0.12) 0%, rgba(244, 208, 80, 0) 100%),
linear-gradient(0deg, #FFFFFF, #FFFFFF)"
      backgroundAttachment="fixed"
      w={w}
    >
      {children}
    </Box>
  );
};
