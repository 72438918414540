import { VStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { useTranslation } from '@/app/i18n/client';
import { SvgIconCell2 } from '@/components/assets/IconCell2';
import { SvgIconNft } from '@/components/assets/IconNft';
import { SvgIconPerformance } from '@/components/assets/IconPerformance';
import { SvgIconShortRibbon } from '@/components/assets/IconShortRibbon';
import { ListTable } from '@/components/common/ListTable/ListTable';
import { MAX_CELL_COUNT } from '@/constants';
import { roundUpByDigit } from '@/utils/math';

type Props = {
  nfts?: number;
  facilities?: number;
  sumPlantCapacityNumber?: number;
  myRevealedCellsCount?: number;
  allRevealedCellsCount?: number;
  donationAmount?: number;
  myDonationAmount?: number;
};

export const InformationDetail: FC<Props> = ({
  nfts,
  facilities,
  sumPlantCapacityNumber,
  myRevealedCellsCount,
  allRevealedCellsCount,
  donationAmount,
  myDonationAmount,
}) => {
  const router = useRouter();
  const { lng } = router.query;
  const { t } = useTranslation('home');
  const allCells = 10368000000;

  return (
    <VStack gap="8" py="6" w="full">
      <ListTable
        icon={<SvgIconNft size={16} color="var(--chakra-colors-text-purple)" />}
        title={t(
          'info_label.environmental_contribution_facilities',
          '環境貢献設備'
        )}
        items={[
          {
            label: t('info_label.nft', 'カード'),
            value: `${nfts?.toLocaleString()}${lng === 'ja' ? ' 個' : ''}`,
          },
          {
            label: t('info_label.number_of_facilities', '種類'),
            value: `${facilities?.toLocaleString()}${
              lng === 'ja' ? ' 個' : ''
            }`,
          },
        ]}
      />
      <ListTable
        icon={
          <SvgIconPerformance
            fill="var(--chakra-colors-text-lightBlue)"
            size="20px"
          />
        }
        title={t('info_label.co2_reduction', 'CO2削減')}
        items={[
          {
            label: t('info_label.co2_reduction_power', 'CO2削減パワー'),
            value: `${roundUpByDigit(sumPlantCapacityNumber || 0, 2)} kg/d`,
          },
        ]}
      />
      <ListTable
        icon={<SvgIconCell2 color="#3AD57F" size={16} />}
        title={t('info_label.cell', 'マスをグリーンに')}
        items={[
          {
            label: t('info_label.you_revealed_cells', 'あなた'),
            value: myRevealedCellsCount?.toLocaleString(),
          },
          {
            label: t('info_label.all_revealed_cells', 'みんな'),
            value: allRevealedCellsCount?.toLocaleString(),
          },
          {
            label: t('info_label.can_reveal_cells', '今年の目標マス'),
            value: MAX_CELL_COUNT.toLocaleString(), // TODO: 可変
          },
          {
            label: t('info_label.all_cells', '世界のマスの数'),
            value: allCells.toLocaleString(),
          },
        ]}
      />
      <ListTable
        icon={<SvgIconShortRibbon color="#F8B62D" />}
        title={t('info_label.donation', '寄付金額')}
        items={[
          {
            label: t('info_label.your_donation_amount', 'あなた'),
            value: `¥ ${myDonationAmount?.toLocaleString()}`,
          },
          {
            label: t('info_label.all_donation_amount', 'みんな'),
            value: `¥ ${donationAmount?.toLocaleString()}`,
          },
        ]}
      />
    </VStack>
  );
};
